<template>
    <modal-lateral ref="modalMensajes" titulo="Mensajes">
        <div id="scrollGeneral" class="white-scroll overflow-auto px-4 mr-3" style="height:calc(100vh - 230px);" @scroll.passive="handleScroll">
            <button v-show="mensajes.length > 0" class="scrollToTopBtn position-absolute" @click="restablecerScroll">
                👇
            </button>
            <div v-for="(msg, idx) in mensajes" :key="idx" class="card-mensaje mb-3">
                <div class="row mx-0">
                    <div class="col-12 text-gris f-15 pt-1 px-1 ">
                        <p class="">
                            {{ msg.mensaje }}
                        </p>
                    </div>
                </div>
                <div class="row mx-0 my-3 mt-3 a-center">
                    <div class="col-xl-6 col-lg position-relative">
                        <img :src="msg.creador.imagen" width="55" height="55" class="rounded-circle obj-cover position-absolute" />
                        <div class="border name_user text-center px-3 pl-5 tres-puntos">
                            {{ msg.creador.nombre }}
                        </div>
                    </div>
                    <div class="col-auto ml-auto text-gris f-15">
                        {{ formatearFecha(msg.created_at, 'D/M/Y HH:mm A') }}
                    </div>
                </div>
            </div>
            <infinite-loading ref="infiniteLoading" direction="top" @infinite="listarMensajes">
                <div slot="spinner">
                    <div>
                        Cargando...
                    </div>
                </div>
                <div slot="no-more" class="text-muted">
                    <span v-if="true">No hay más mensajes</span>
                </div>
                <div slot="no-results" class="text-muted">
                    <span v-if="mensajes.length <=0">
                        No se encuentran mensajes
                    </span>
                </div>
            </infinite-loading>
        </div> 
        <div class="row mx-3 my-2 border" />
        <ValidationObserver ref="validacion">
            <div class="row-mx-0">
                <div class="col-12 my-3 position-relative">
                    <i class="icon-send position-absolute z-1 f-22 cr-pointer" style="right:25px;bottom:35px;" @click="guardarMensaje" />
                    <ValidationProvider v-slot="{errors}" rules="required|max:500" name="comentario">
                        <el-input v-model="model.mensaje" type="textarea" placeholder="Comentar" class="w-100 area-radius input-chat" :rows="4" @keydown.native="detectarEnter($event)" />
                        <span class="text-danger w-100 f-12"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div> 
        </ValidationObserver>
    </modal-lateral>
</template>

<script>
import MensajesService from '~/services/mensajes/mensajes'
import InfiniteLoading from 'vue-infinite-loading'
export default {
    components: {
        InfiniteLoading,
    },
    sockets:{
        cursos_mensajes(value){ 
            //console.log("hola mundo",value)
            try {
                const {mensaje} = JSON.parse(value)
                this.listarMensajes()

            } catch (e){
                this.error_catch(e)
            }
        }
    },
    data(){
        return {
            id_curso: parseInt(this.$route.params.id_curso),
            mensajes:[],
            page:1,
            nombreProyecto:'mac_learning_laravel_',
            nameCanal: 'mac_learning_laravel_mensajes',
            model:{
                mensaje: ''
            },
            id_user: null,
            channels:[]
        }
    },
    methods: {
        toggle(){
            this.id_user = this.$usuario.id
            this.limpiarForm()
            this.$refs.modalMensajes.toggle();
            this.subscribeChanel()
        },
        async listarMensajes($state= false){
            try {
                const state = $state
                var params = {
                    page: this.page,
                    id_user: this.id_user,
                    id_curso: this.id_curso
                }
                if(!this.id_user){
                    console.log('no existe usuario');
                    if(state){
                        state.complete()
                    }
                    return false

                }
                this.loading = true
                const {data:{data}} = await MensajesService.listarMensajesUsuario(params)
                this.loading = false
                if (data.mensajes.data.length > 0){
                    if(this.page==1){
                        this.mensajes = []
                    }
                    this.page += 1;
                    // let ordenados = _.sortBy(data.chats.data, ['created_at'])
                    // this.mensajes = this.mensajes.concat( data.mensajes.data)

                    let ordenados = _.sortBy(data.mensajes.data, ['created_at'])
                    this.mensajes = ordenados.concat( this.mensajes)
                    this.restablecerScroll()
       
                    if(state){
                        state.loaded()
                    }

                } else {
                    if(state){
                        state.complete()
                    }
                }
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
        detectarEnter(key){
            let code = key.keyCode
            if(code === 13){
                this.guardarMensaje()
            }
        },
        async guardarMensaje(){
            try {
                console.log("Guardar mensaje 1")
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'El comentario es obligatorio', 'warning')
                    return false
                }
                this.model.id_curso = this.id_curso
                this.model.id_user = this.id_user
                if(!this.model.id_user){
                    this.notificacion('Mensaje', 'Usuario no selecionado', 'warning')
                    return false
                }
                const {data:{data}} = await MensajesService.guardarMensaje(this.model)
                this.model.mensaje = ''
                this.$refs.validacion.reset()

            } catch (e){
                this.error_catch(e)
            }
        },
        subscribeChanel(){
            if (this.sockets=== undefined) this.notificacion('Mensaje','EL socket no esta importado','warning')
            this.sockets.subscribe(`${this.nameCanal}_${this.id_user}`, (value)=>{
                const {mensaje} = JSON.parse(value)
                if(this.id_user=== parseInt(mensaje.id_user)){
                    this.mensajes.push(mensaje)
                    if(parseInt(mensaje.created_by) !== this.$usuario.id){// Notifica cuando recibo mensaje de otra persona
                        this.notificacion('Mensaje', 'Nuevo mensaje', 'success')
                    }
                    this.deslizar()

                }
            })
            this.guardarCanalesSubscritos(`${this.nameCanal}-${this.id_user}`)
        },
        unsubscribe(){
            this.unsubscribe_channels()
            this.mensajes= []
            if(this.$refs.infiniteLoading){
                this.$refs.infiniteLoading.stateChanger.reset()
            }
        },
        unsubscribe_channels(){
            this.channels.forEach(element => {
                this.sockets.unsubscribe(element);
            });
            this.channels = []
        },
        guardarCanalesSubscritos(nameCanal){
            if(!this.channels.some(e => e===nameCanal)){
                this.channels.push(nameCanal)
            }
        },
        async deslizar(){
            if (this.necesitaDeslizar()){
   
                await this.delay_scroll(100)
                this.restablecerScroll()
            } else {
            }
        },
        delay_scroll(ms=100){
            return new Promise(resolve => setTimeout(resolve, ms));
        },      
        restablecerScroll(){
            var  element = document.getElementById('scrollGeneral')
            if(element){
                if(element.scrollTo){
                    element.scrollTo({
                        top: element.scrollHeight,
                        behavior: "smooth"
                    })
                }
            }
        },
        necesitaDeslizar(){
            const rootElement = document.getElementById('scrollGeneral')
            if(rootElement === undefined || rootElement === null){
                return false
            }
            var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight
            return (rootElement.scrollTop / scrollTotal ) > 0.80

        },
        ocultarIcono(){
            var scrollToTopBtn = document.querySelector(".scrollToTopBtn")
            if(scrollToTopBtn){
                scrollToTopBtn.classList.remove("showBtn")
            }
        },
        handleScroll(){
            var scrollToTopBtn = document.querySelector(".scrollToTopBtn")
            if (this.necesitaDeslizar()){
                if(scrollToTopBtn){
                    scrollToTopBtn.classList.remove("showBtn")
                }
                // Hide button
            } else {
                if(scrollToTopBtn){
                    scrollToTopBtn.classList.add("showBtn")
                }
                // Show button
            }
        },
        limpiarForm(){
            this.model.mensaje = ''
            if(this.$refs.validacion){
                this.$refs.validacion.reset()
            }
            if(this.$refs.infiniteLoading){
                this.page = 1                
                this.$refs.infiniteLoading.stateChanger.reset(); 
            }
            this.mensajes =[]
        }
    }
}
</script>

<style lang="scss" scoped>
.card-mensaje{
    min-height: 118px;
    background: #fff;
    border-radius: 12px !important;
    border: 1px solid #DFDFDF !important;
    img{
        left: 7px; 
        top: -15px ;
    }
    .name_user{
        min-height: 32px;
        color: var(--color-gris !important);
        background: #D9DCE7;
        border-radius: 0px 20px 20px 0px;
        
    }
}

.white-scroll::-webkit-scrollbar-track{ height: 6px; width:6px; -webkit-box-shadow:inset 0 0 6px #00000029; background-color:#DFDFDF; border-radius: 5px; }
.white-scroll::-webkit-scrollbar{ background-color:#DFDFDF; width:10px; height: 10px; border-radius: 5px; }
.white-scroll::-webkit-scrollbar-thumb{ -webkit-box-shadow:inset 0 0 6px #00000029; background-color:#FFF; border-radius: 18px; }

.descripcion{
    height: 80px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 19px;
}
 .scrollToTopBtn {
    background-color:#4d5d75;
    border: none;
    border-radius: 50%;
    position: fixed;
    top: 57px;
    right: 32px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    line-height: 48px;
    width: 48px;
    /* keep it at the top of everything else */
    z-index: 100;
    /* hide with opacity */
    opacity: 0;
    /* also add a translate effect */
    transform: translateY(100px);
    /* and a transition */
    transition: all .5s ease
 }
  .showBtn {
  opacity: 1;
  transform: translateY(0)
}
</style>